import { createRoot } from 'react-dom/client';
import React from 'react';
import { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'
import CourseTable from './components/CourseTable'
import Footer from './components/Footer'
import Notice from './components/Notice'

import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

let { NODE_ENV } = process.env;

var faro = initializeFaro({
  url: '/t',
  app: {
    name: 'dev',
    version: '0.1.0',
    environment: NODE_ENV
  },
  instrumentations: [
    ...getWebInstrumentations(),
    new TracingInstrumentation(),
  ],
});


/*
import Story from './components/Story'

const armedUrl = new URL(
  './assets/defenses-framed-logo-v1.png',
  import.meta.url
);

const collisionUrl = new URL(
  './assets/collisions-framed-logo-v6.png',
  import.meta.url
);

const quirksUrl = new URL(
  './assets/quirks-framed-logo-v2.png',
  import.meta.url
);
const goalBrandedUrl = new URL(
  './assets/ecosystem-antioppressive-with-logo-v1.png',
  import.meta.url
);
*/

const courses = [
    { name: 'Build an AI VoiceBot', levels: 3, audience: 'Adults; K-12', language: 'Python', url: 'https://docs.civichacker.dev' , urlText: 'Start', kind: 'Guided'},
]

const whiteSymbolUrl = new URL(
  './assets/ch-logo-white.png',
  import.meta.url
);


const container = document.getElementById('app');
const root = createRoot(container);

const heroDescription = "A project to explore how AI systems impact our collective well-being and livelihood";
const heroHeading = "Welcome to the Playground";

const navigation = [
  { name: 'Run the Shell', href: '/shell', targetBlank: false, additionalClassNames: "dev-shell" },
  { name: 'Resources', href: 'https://docs.civichacker.dev', targetBlank: true, additionalClassNames: "humanquirks-resources" },
]

function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
     <div className="bg-gray-900">
          <header className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
              <div className="flex lg:flex-1">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Civic Hacker</span>
                  <img
                    className="h-8 w-auto"
                    src={whiteSymbolUrl}
                    alt="Civic Hacker logo"
                  />
                </a>
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href}
                    target={item.targetBlank ? '_blank': '_self'}
                    className={`${item.additionalClassNames} text-sm font-semibold leading-6 text-white`}>
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                <div className="flex items-center justify-between">
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Civic Hacker</span>
                    <img
                      className="h-8 w-auto"
                      src={whiteSymbolUrl}
                      alt="Civic Hacker logo"
                    />
                  </a>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-400"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/25">
                    <div className="space-y-2 py-6">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          target={item.targetBlank ? '_blank': '_self'}
                          className={`${item.additionalClassNames} -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800`}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>

          <div className="pt-14">
            <div className="pb-4 pt-8 sm:pb-2 sm:pt-12 lg:pb-8">
                <h1 className="text-5xl text-white text-center pt-6 pb-12 font-semibold">{heroHeading}</h1>
              <div className="mx-auto max-w-7xl md:max-w-2xl lg:max-w-3xl px-8 lg:px-12">
                <CourseTable name={"Experiences to choose from"} description={"Here's a mix of challenges and experiments for your learning enjoyment."} courses={courses} />
              </div>
            </div>
          </div>
        </div>
        <div className="relative max-w-5xl text-base leading-7 text-gray-700 lg:max-w-5xl w-full mx-auto px-4 lg:px-0 flex flex-col">
        </div>
        <div className="relative max-w-3xl text-base leading-7 text-gray-700 lg:max-w-4xl w-full mx-auto px-6 lg:px-0 flex flex-col my-8">
            <h2 className="mt-8 text-3xl font-bold tracking-tight text-gray-900 text-center">We've Joined forces with NIST</h2>
            <Notice />

        </div>
        <div className="flex h-[705px] md:h-[450px] lg:h-96" id="top-of-funnel">
          <iframe src="https://app.civichacker.com/campaign/signup/civichacker-dev/" sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms allow-popups" loading="lazy" className="flex-1" scrolling="no"></iframe>
        </div>
        <Footer name={"Civic Hacker Labs"}/>
      </>
  )
}

root.render(<Landing />);
